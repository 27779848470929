import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroCarousel from "../components/indexSlides/heroCarousel"
import SlideOne from "../components/indexSlides/slideOne"
// import QuoteCarousel from "../components/indexSlides/quoteCarousel"
import SlideTwo from "../components/indexSlides/slideTwo"
import SocialSection from "../components/indexSlides/socialSection"
import SlideZero from "../components/indexSlides/slideZero"
import { useTranslation } from "react-i18next"

const BlogIndex = ({ pageContext: { originalPath } }) => {
  const { t } = useTranslation()

  return (
    <Layout originalPath={originalPath}>
      <Seo title={t("home")} />
      <HeroCarousel />
      <SlideZero />
      <SlideOne />
      {/* <QuoteCarousel /> */}
      <SlideTwo />
      <SocialSection />
    </Layout>
  )
}

export default BlogIndex