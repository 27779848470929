import { Box, Card, CardContent, Grid, Typography } from "@mui/material"
import React from "react"
import SectionWrapper from "../sectionWrapper"

const Big = ({ children }) => (
  <Typography
    variant="h4"
    component="span"
    color="primary"
    display="block"
    align="left"
  >
    {children}
  </Typography>
)

const imagineData = [
  {
    text: (
      <Box>
        <Typography>
          at 15 years of age only <Big>0.5%</Big> of girls wish to become ICT
          professionals, compared to 5% of boys. Girls lose interest in STEM as
          they get older due to gendered social expectation of profession.
        </Typography>
      </Box>
    ),
    source: "OECD Report, 2018",
  },
  {
    text: (
      <Box>
        <Typography>
          women are still <Big>8%</Big>less likely than men to own a mobile
          phone, and <Big>20%</Big> less likely to use the internet on a mobile.
        </Typography>
      </Box>
    ),
    source: "GSMA Report, 2020",
  },
  {
    text: (
      <Box>
        <Typography>
          while women have been behind a number of scientific discoveries
          throughout history, just <Big>30%</Big> of researchers worldwide and 
          <Big>35%</Big> of all students enrolled in STEM-related fields of
          study are women.
        </Typography>
      </Box>
    ),
    source: "The Nobel Foundation, 2019",
  },
  {
    text: (
      <Box>
        <Typography>
          <Big>85%</Big> of the women that are online either witness or
          experience harassment and other forms of violence
        </Typography>
      </Box>
    ),
    source: "The Economist, 2020",
  },
]

const SlideZero = () => {
  return (
    <SectionWrapper>
      <Typography variant="h2">
        gender equality is nowhere near to being achieved in tech and innovation
      </Typography>
      <Grid container spacing={2}>
        {imagineData.map(({ text, source }, index) => (
          <Grid key={index} item xs={12} md={3}>
            <Card style={{ height: "100%" }}>
              <CardContent>
                {text}
                <Box pt={3}>
                  <Typography variant="overline">{source}</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </SectionWrapper>
  )
}

export default SlideZero