import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material"
import { Button } from "gatsby-theme-material-ui"
import React from "react"
import SectionWrapper from "../sectionWrapper"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

const blocksData = [
  {
    title: "new coalition",
    text:
      "We are launching a multi-stakeholder Action Coalition on Technology and Innovation for Gender Equality",
    link: "/about",
  },
  {
    title: "bold commitments",
    text:
      "We are making transformative commitments to leverage technology for gender equality.",
    link: "https://commitments.generationequality.org/",
    external: true,
  },
  {
    title: "job fair",
    text:
      "We are creating a job fair on tech and innovation to make available to young women all around the world opportunities to join the digital and tech economies.",
    link: "https://jobs.techforgenerationequality.org/",
  },
  {
    title: "festival of feminist voices",
    text:
      "We are organizing a festival of feminist voices on Equal Digital Rights at the Generation Equality Forum in Paris (30 June – 2 July 2021)",
    link: "/campaign",
  },
]

const SlideTwo = () => {
  return (
    <SectionWrapper>
      <Typography variant="h2">
        we are putting
        <br />
        that imagination into action
        <br />
        for the next five years
      </Typography>
      <Grid container spacing={2}>
        {blocksData.map(({ title, text, link, external }, index) => (
          <Grid key={index} item xs={12} md={3}>
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <div>
                <CardHeader
                  title={title}
                  style={{ height: 88, alignItems: "flex-start" }}
                />
                <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Typography>{text}</Typography>
                </CardContent>
              </div>
              <CardActions>
                <Button
                  to={link}
                  target={external ? "_blank" : "_self"}
                  rel={external ? "noreferrer" : undefined}
                  endIcon={<ArrowForwardIcon />}
                >
                  Read more
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </SectionWrapper>
  )
}

export default SlideTwo
