import { Box } from "@mui/material"
import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import SectionWrapper from "../sectionWrapper"

const SocialSection = () => {
  const [socialWall, setSocialWall] = useState(null)

  useEffect(() => {
    setSocialWall(
      <Box>
        <ul
          className="juicer-feed"
          data-feed-id="generationequality-53fb0845-d4da-4034-9562-2b365a32504b"
          data-per="9"
          // data-truncate="100"
        >
          <h1 className="referral">
            {/* <a href="https://www.juicer.io">Powered by Juicer.io</a> */}
          </h1>
        </ul>
      </Box>
    )
  }, [])
  return (
    <>
      <Helmet>
        <script
          src="https://assets.juicer.io/embed.js"
          type="text/javascript"
        ></script>
        <link
          href="https://assets.juicer.io/embed.css"
          media="all"
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>
      <SectionWrapper light>{socialWall}</SectionWrapper>
    </>
  )
}

export default SocialSection
