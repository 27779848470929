import { Box, Container, Typography } from "@mui/material"
import React from "react"
import SectionWrapper from "../sectionWrapper"
import { useTheme } from "@mui/material/styles"



const SlideOne = () => {
  const { palette } = useTheme()

  return (
    <SectionWrapper light>
      <Typography variant="h2" style={{ paddingBottom: 8 }}>
        now imagine a world where
      </Typography>
      <Typography variant="body2" component="p">
        women and girls in all their diversity
        <br />
        have equal opportunities
        <br />
        to safely and meaningfully
        <br />
        access, use, lead, and design technology and innovation
        <br />
        with{" "}
        <span style={{ color: palette.primary.main }}>
          freedom of expression, joy, and boundless potential
        </span>
        .
      </Typography>

      <Container maxWidth="sm" disableGutters>
        <Box position="relative" mt={4} pb={`${(9 / 16) * 100}%`} height={0}>
          <iframe
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            src="https://www.youtube.com/embed/FUK_kTRLlTU?rel=0&modestbranding=1"
            title="YouTube video player"
            frameBorder="0"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
      </Container>
    </SectionWrapper>
  )
}

export default SlideOne