import { Box, Grid, Typography } from "@mui/material"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Button } from "gatsby-theme-material-ui"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import "./carouselStyle.css"
import SectionWrapper from "../sectionWrapper"

const HeroCarousel = () => {
  const { image1, image2, image3 } = useStaticQuery(
    graphql`
      {
        image1: file(name: { eq: "carousel_techforequality" }) {
          id
          name
          childImageSharp {
            gatsbyImageData(aspectRatio: 1.777, placeholder: BLURRED)
          }
        }

        image2: file(name: { eq: "carousel_jobfair" }) {
          id
          name
          childImageSharp {
            gatsbyImageData(aspectRatio: 1.777, placeholder: BLURRED)
          }
        }

        image3: file(name: { eq: "Carousel_join" }) {
          id
          name
          childImageSharp {
            gatsbyImageData(aspectRatio: 1.777, placeholder: BLURRED)
          }
        }
      }
    `
  )

  const carouselData = [
    {
      title: "#techforequality",
      description:
        "A digital moment amplifying stories, data, products and practices across the globe led and driven by feminist leaders and movements about Equal Digital Rights.",
      link: "/campaign",
      image: image1,
    },
    {
      title: "Job Fair",
      description:
        "A global online job fair connecting young women to opportunities in the digital economy and connecting talents across sectors, geographies and generations.",
      link: "https://jobs.techforgenerationequality.org/",
      image: image2,
    },
    {
      title: "Commit and Join",
      description:
        "Support one of our collective actions to spark a more equal and diverse digital transformation. Make a commitment and join the coalition!",
      link: "/join",
      image: image3,
    },
  ]

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  }
  
  return (
    <SectionWrapper light>
      <Slider {...settings}>
        {carouselData.map(({ description, link, title, image }) => (
          <Box key={title} bgcolor="background.default" borderRadius={2}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={8} style={{ fontSize: 0 }}>
                <GatsbyImage image={getImage(image)} alt={title} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  p={3}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  height={1}
                >
                  <Box>
                    <Typography variant="h6">{title}</Typography>
                    <Typography>{description}</Typography>
                  </Box>
                  <Box pt={2}>
                    <Button
                      variant="contained"
                      disableElevation
                      to={link}
                      target="_self"
                      endIcon={<ArrowForwardIcon />}
                    >
                      Read more
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Slider>
    </SectionWrapper>
  )
}

export default HeroCarousel